import $ from 'jquery';

const $document = $(document);
const $dropdown = $('.js-dropdown');
const $dropdownToggle = $('.js-dropdown-toggle');

/**
 * Activates an dropdown given a dropdown id.
 * @param {String} dropdown The id attribute for a dropdown.
 */
function activate(dropdown) {
  const $dropdown = $(`.js-dropdown[id="${dropdown}"]`);
  $dropdown.addClass('is-toggled').attr({ 'aria-hidden': 'false' });
  const $dropdownToggle = $(`.js-dropdown-toggle[aria-controls="${dropdown}"]`);
  $dropdownToggle.addClass('is-toggled').attr({ 'aria-expanded': 'true' });
  const $dropdownParent = $dropdown.closest('.js-dropdown-parent');
  $dropdownParent.mouseleave(() => deactivate(dropdown));
}

/**
 * Deactives an dropdown given a dropdown id. If none is provided, then ALL
 * dropdowns will be deactivated.
 * @param {String} id The id attribute for a dropdown.
 */
function deactivate(dropdown = false) {
  let $dropdown = $(`.js-dropdown`);
  let $dropdownToggle = $(`.js-dropdown-toggle`);
  if (dropdown) {
    $dropdown = $dropdown.filter(`[id="${dropdown}"]`);
    $dropdownToggle = $dropdownToggle.filter(`[aria-controls="${dropdown}"]`);
  }
  $dropdown.removeClass('is-toggled').attr({ 'aria-hidden': 'true' });
  $dropdownToggle.removeClass('is-toggled').attr({ 'aria-expanded': 'false' });
}

function deactivateAll() {
  deactivate();
}

/**
 * Event handler for activating dropdowns. Used on hover/focus.
 */
function handleToggle() {
  const $dropdownToggle = $(this);
  const dropdown = $dropdownToggle.attr('aria-controls');
  const $dropdown = $(`.js-dropdown[id="${dropdown}"]`);
  if (!$dropdown.hasClass('is-toggled')) {
    deactivateAll();
    activate(dropdown);
  } else {
    deactivateAll();
  }
}

$dropdownToggle.on('click', handleToggle);

/**
 * Close all dropdowns when clicking outside a dropdown.
 */
$document.on('click', function (event) {
  const $target = $(event.target);
  if (
    !$target.closest($dropdown).length &&
    !$target.closest($dropdownToggle).length
  ) {
    deactivateAll();
  }
});
