import $ from 'jquery';

$('.section').each((index, section) => {
  const $section = $(section);
  const $sectionPrev = $section.prev('.section');
  const $sectionNext = $section.next('.section');

  /**
   * Automatically prepend a .section__background.background element to any
   * section that doesn't already have one.
   */
  if ($section.find('.section__background').length <= 0) {
    $section.prepend(
      '<div class="section__background background"></div> <!-- /.section__background -->'
    );
  }

  /**
   * Store the classes of the previous and next .section elements (if they
   * exist), as it allows us to work around CSS selector limitations, in
   * addition to being more readible.
   */
  if ($sectionPrev) {
    $section.attr('data-section-previous', $sectionPrev.attr('class'));
  }
  if ($sectionNext) {
    $section.attr('data-section-next', $sectionNext.attr('class'));
  }
});
