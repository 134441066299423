export function getMarkerGradient(colors) {
  // Remove duplicate colours
  colors = [...new Set(colors)];

  if (colors.length <= 0) {
    return '#bbbbbb';
  }

  if (colors.length === 1) {
    return colors[0];
  }

  const segments = [];
  const segmentLength = 100 / colors.length;

  for (let index = 0; index < colors.length; index++) {
    const x = Math.round(segmentLength * index);
    const y = Math.round(segmentLength * (index + 1));
    segments.push(`${colors[index]} ${x}% ${y}%`);
  }

  return `conic-gradient(${segments.join(',')})`;
}

export function getMarkerIcon(background = '#bbbbbb') {
  const iconWidth = 22;
  const iconHeight = 30;

  if (!background) {
    background = '#bbbbbb';
  }
  return L.icon({
    iconSize: [iconWidth, iconHeight],
    iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(
      `<svg width="${iconWidth}" height="${iconHeight}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${iconWidth} ${iconHeight}" xml:space="preserve">
        <clipPath id="clip">
          <path d="M11.5 0H11C5 0 0 4.4 0 10.3v.3C0 18.3 7.4 27.9 11.2 30h.1C15 27.9 22 18.3 22 10.6v-.3C22 4.4 17.4 0 11.5 0z" />
        </clipPath>
        <foreignObject width="${iconWidth}" height="${iconHeight}" clip-path="url(#clip)">
          <div xmlns="http://www.w3.org/1999/xhtml" style="background: ${background}; width: 100%; height: 100%;"></div>
        </foreignObject>
      </svg>`
    )}`,
    shadowSize: [iconWidth + 2, iconHeight + 2],
    shadowAnchor: [(iconWidth + 2) / 2 - 2, (iconHeight + 2) / 2 - 2],
    shadowUrl: `${PRIMARY_SITE_URL}/assets/marker-shadow.png`,
  });
}

export function getMarkerVideoIcon(background = '#bbbbbb') {
  const iconWidth = 44;
  const iconHeight = 60;
  if (!background) {
    background = '#bbbbbb';
  }
  return L.icon({
    iconSize: [iconWidth, iconHeight],
    iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(
      `<svg width="${iconWidth}" height="${iconHeight}" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 ${iconWidth} ${iconHeight}">
      <clipPath id="background">
        <path d="M22.9 0H22C10.05 0 0 8.82 0 20.51v.67C0 36.59 14.84 55.76 22.36 60h.18C30.06 55.76 44 36.59 44 21.18v-.67C44 8.82 34.85 0 22.9 0z"/>
      </clipPath>
      <foreignObject width="${iconWidth}" height="${iconHeight}" clip-path="url(#background)">
        <div xmlns="http://www.w3.org/1999/xhtml" style="background: #111111; width: 100%; height: 100%;"></div>
      </foreignObject>
      <clipPath id="foreground">
        <path d="M31.2 24.1 15.06 32c-.14.06-.29.1-.46.1-.61 0-1.11-.48-1.11-1.08V15.18c.05-.56.52-.99 1.1-.99.17 0 .32.04.47.1l16.14 7.9c.35.18.59.54.59.96 0 .41-.24.77-.59.95z"/>
      </clipPath>
      <foreignObject width="${iconWidth}" height="${iconHeight}" clip-path="url(#foreground)">
        <div xmlns="http://www.w3.org/1999/xhtml" style="background: ${background}; width: 100%; height: 100%;"></div>
      </foreignObject>
      </svg>`
    )}`,
    shadowSize: [iconWidth + 2, iconHeight + 2],
    shadowAnchor: [(iconWidth + 2) / 2 - 2, (iconHeight + 2) / 2 - 2],
    shadowUrl: `${PRIMARY_SITE_URL}/assets/marker-shadow.png`,
  });
}

/*

<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 44 60">


</svg>

*/
