import debounce from 'lodash/debounce';

const SELECTOR_CITATIONS = '.js-citations';
const SELECTOR_CITATIONS_BUTTON = '.js-citations-button';
const SELECTOR_CITATIONS_CONTENT = '.js-citations-content';

const CLASS_TOGGLED = 'is-toggled';

/**
 * Open an citation, incl. closing adjacent citations.
 */
const open = (citation) => {
  citation.classList.add(CLASS_TOGGLED);
  const citationToggle = document.querySelector(SELECTOR_CITATIONS_BUTTON);
  if (citationToggle) {
    citationToggle.setAttribute('aria-expanded', 'true');
  }
};

/**
 * Close an citation.
 */
const close = (citation) => {
  citation.classList.remove(CLASS_TOGGLED);
  const citationToggle = document.querySelector(SELECTOR_CITATIONS_BUTTON);
  if (citationToggle) {
    citationToggle.setAttribute('aria-expanded', 'false');
  }
};

/**
 * Toggle an citation.
 */
const toggle = (citation) => {
  if (citation.classList.contains(CLASS_TOGGLED)) {
    close(citation);
  } else {
    open(citation);
  }
};

/**
 * Update an citation, ie. set content height.
 */
const update = (citation) => {
  const citationContent = citation.querySelector(SELECTOR_CITATIONS_CONTENT);
  if (!citationContent) {
    return;
  }
  citationContent.style.height = `${citationContent.scrollHeight}px`;
};

/**
 * Initialize an citation, ie. bind event listeners.
 */
const initialize = (citation) => {
  update(citation);
  window.addEventListener(
    'resize',
    debounce(() => update(citation), 250)
  );
  const citationToggle = citation.querySelector(SELECTOR_CITATIONS_BUTTON);
  if (!citationToggle) {
    return;
  }
  citationToggle.addEventListener('click', () => toggle(citation));
};

Array.from(document.querySelectorAll(SELECTOR_CITATIONS)).forEach(
  (citation) => {
    initialize(citation);
  }
);
