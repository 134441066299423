import $ from 'jquery';
import debounce from 'lodash/debounce';

const $window = $(window);
const $body = $('body');

const $figures = $('.js-figure');

function updateFiguresWidth() {
  $figures.map(updateFigureWidth);
}

function updateFigureWidth(index, figure) {
  const $figure = $(figure);
  const figureDirection = $figure.attr('data-figure-direction');
  const figureLeft = $figure.position().left;

  if (figureDirection === 'right') {
    const clientWidth = $body.prop('clientWidth');
    const width = clientWidth - figureLeft;
    $figure.css({ 'max-width': `${width}px` });
  }

  if (figureDirection === 'left') {
    const figureWidth = $figure.width();
    const width = figureLeft + figureWidth;
    $figure.css({ 'max-width': `${width}px` });
  }
}

$window.on('resize', debounce(updateFiguresWidth, 250));

updateFiguresWidth();
