import readingTime from 'reading-time/lib/reading-time';

const $time = $('.js-reading-time');
const $text = $('.js-reading-text');

if ($time.length >= 1 && $text.length >= 1) {
  const text = $text.text();
  const readtime = readingTime(text);
  const minutes = Math.round(readtime.minutes);
  $time.text(`${minutes} min. read`);
}
