// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const $body = $('body');
const $navigation = $('.js-navigation');
const $navigationToggle = $('.js-navigation-toggle');

$navigationToggle.on('click', () => {
  $body.toggleClass('is-navigation-toggled');
  $navigation.toggleClass('is-toggled');
  $navigationToggle.toggleClass('is-toggled');
});
