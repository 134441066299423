import ClipboardJS from 'clipboard';

const clipboard = new ClipboardJS('.js-clipboard');

clipboard.on('success', ({ trigger }) => {
  trigger.textContent = trigger.getAttribute('data-clipboard-success');
  setTimeout(() => {
    trigger.textContent = trigger.getAttribute('data-clipboard-default');
  }, 3000);
});
