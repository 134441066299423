import debounce from 'lodash/debounce';

const SELECTOR_ACCORDIONS = '.js-accordions';
const SELECTOR_ACCORDION = '.js-accordion';
const SELECTOR_ACCORDION_TOGGLE = '.js-accordion-toggle';
const SELECTOR_ACCORDION_CONTENT = '.js-accordion-content';

const CLASS_TOGGLED = 'is-toggled';

/**
 * Open an accordion, incl. closing adjacent accordions.
 */
const open = (accordion) => {
  closeAdjacent(accordion);
  accordion.classList.add(CLASS_TOGGLED);
  const accordionToggle = document.querySelector(SELECTOR_ACCORDION_TOGGLE);
  if (accordionToggle) {
    accordionToggle.setAttribute('aria-expanded', 'true');
  }
};

/**
 * Close an accordion.
 */
const close = (accordion) => {
  accordion.classList.remove(CLASS_TOGGLED);
  const accordionToggle = document.querySelector(SELECTOR_ACCORDION_TOGGLE);
  if (accordionToggle) {
    accordionToggle.setAttribute('aria-expanded', 'false');
  }
};

/**
 * Close accordions adjacent to an accordion.
 */
const closeAdjacent = (accordion) => {
  const accordionParent = accordion.closest(SELECTOR_ACCORDIONS);
  if (!accordionParent) {
    return;
  }
  const accordionSiblings = accordionParent.querySelectorAll(
    SELECTOR_ACCORDION
  );
  if (!accordionSiblings) {
    return;
  }
  Array.from(accordionSiblings).forEach(close);
};

/**
 * Toggle an accordion.
 */
const toggle = (accordion) => {
  if (accordion.classList.contains(CLASS_TOGGLED)) {
    close(accordion);
  } else {
    open(accordion);
  }
};

/**
 * Update an accordion, ie. set content height.
 */
const update = (accordion) => {
  const accordionContent = accordion.querySelector(SELECTOR_ACCORDION_CONTENT);
  if (!accordionContent) {
    return;
  }
  accordionContent.style.height = `${accordionContent.scrollHeight}px`;
};

/**
 * Initialize an accordion, ie. bind event listeners.
 */
const initialize = (accordion) => {
  update(accordion);
  window.addEventListener(
    'resize',
    debounce(() => update(accordion), 250)
  );
  const accordionToggle = accordion.querySelector(SELECTOR_ACCORDION_TOGGLE);
  if (!accordionToggle) {
    return;
  }
  accordionToggle.addEventListener('click', () => toggle(accordion));
};

Array.from(document.querySelectorAll(SELECTOR_ACCORDION)).forEach(
  (accordion) => {
    initialize(accordion);
  }
);
