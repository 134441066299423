import MicroModal from 'micromodal';

const options = {
  onShow: (modal) => {
    history.replaceState(null, null, `#${modal.id}`);
  },
  onClose: (modal) => {
    history.replaceState(null, null, '#');
  },
  openTrigger: 'data-modal-open',
  closeTrigger: 'data-modal-close',
  disableScroll: true,
  disableFocus: true,
  awaitCloseAnimation: true,
};

function initializeModal(modalElement) {
  // Append modals to end of </body>, to avoid issues with position: relative
  // parent elements (eg. Flickity carousels).
  document.body.appendChild(modalElement);
  // Allow clicking links with matching hashes to open modals.
  initializeModalLinks(modalElement);
}

function initializeModalLinks(modalElement) {
  const id = modalElement.getAttribute('id');
  Array.from(document.querySelectorAll(`a[href^="#${id}"]`)).forEach(
    (modalLinkElement) => initializeModalLink(modalLinkElement, modalElement)
  );
}

function initializeModalLink(modalLinkElement, modalElement) {
  modalLinkElement.addEventListener('click', (event) => {
    // Necessary to prevent scrolling to "anchor", which is modal at bottom of DOM.
    event.preventDefault();
    // Trigger (show) the associated modal.
    MicroModal.show(modalElement.getAttribute('id'), options);
  });
}

// Only initialize MicroModal if there's a modal on the current page.
if (document.querySelectorAll('.js-modal')) {
  // Initialize each individual modal
  Array.from(document.querySelectorAll('.js-modal')).forEach(initializeModal);
  // Initialize the library (with options)
  MicroModal.init(options);
}

// Automatically open a modal if its id is the url hash.
function handleDOMContentLoaded() {
  const id = window.location.hash.substr(1);
  if (document.querySelector(`.js-modal[id="${id}"]`)) {
    MicroModal.show(id, options);
  }
}
window.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
