import $ from 'jquery';
import L from 'leaflet';
import { getMarkerGradient, getMarkerIcon } from '../application/helpers';

$('.js-leaflet').each((index, container) => {
  const $container = $(container);

  const map = L.map(container).setView([51.05, -114.07], 6);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(map);

  const markers = [];

  $container.find('.js-leaflet-marker').each((index, marker) => {
    const $marker = $(marker);
    const lat = Number($marker.attr('data-lat'));
    const lng = Number($marker.attr('data-lng'));
    const background = $marker.attr('data-background');
    const icon = getMarkerIcon(background);
    const popup = $marker.html();
    markers.push(L.marker([lat, lng], { icon }).bindPopup(popup));
  });

  const featureGroup = L.featureGroup(markers).addTo(map);

  map.fitBounds(featureGroup.getBounds(), { maxZoom: 10 });
});
