import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
jQueryBridget('flickity', Flickity, $);

$('.js-gallery').each((index, gallery) => {
  const $gallery = $(gallery);

  const options = Object.assign(
    {
      arrowShape: `M32.687 84.027a3.557 3.557 0 1 0 5.01-5.01l-25.56-25.59h84.485a3.567 3.567 0 0 0 0-7.124H12.137l25.6-25.56a3.628 3.628 0 0 0 0-5.01 3.537 3.537 0 0 0-5.002-.008l-.008.008L1.066 47.396a3.478 3.478 0 0 0 0 5.01l31.621 31.621z`,
      contain: true,
      draggable: true,
      lazyLoad: true,
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
    },
    $gallery.data('gallery-options')
  );

  $gallery.flickity(options);
});
